import React from 'react'
import ImageHandler from '../../../../shared/components/ImageHandler'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom';

export default function ShopNowImage() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <ImageHandler
                src="/images/banner-1.webp"
                className="w-100"/>
            <div
                className="bg-white d-flex flex-column justify-content-center align-items-center position-absolute-center p-5">
                <h4 className="text-uppercase"> Ellenha eyelashes </h4>
                <small className="text-muted"> {t('shop.shopContentDesc')} </small>
                <span className="text-uppercase hover-underline-animation mt-2 fw-semibold cursor-pointer"
                      onClick={() => navigate('/shop')}>
                    {t('buttons.shopNow')}
                </span>
            </div>
        </>
    )
}

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {RootState} from '../../store/store';
import {routeList} from '../../utils/routes';
import {getInitialRoute} from '../functions/getInitialRoute';
import ImageHandler from './ImageHandler';
import {GoHeart} from 'react-icons/go';
import {PiBagLight} from 'react-icons/pi';
import {CiUser} from 'react-icons/ci';
import {Badge, Dropdown} from 'react-bootstrap';
import authSlice from '../../store/slices/auth.slice';
import {scrollTo} from '../functions/scrollTo';
import {ERole} from '../enums/ERole';
import wishlistSlice from '../../store/slices/wishlist.slice';
import cartSlice from '../../store/slices/cart.slice';

export const pageLogo = '/eyelashLogo.png';

const Header = () => {
    const {t} = useTranslation();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const {isAuthenticated, userData} = useSelector(
        (state: RootState) => state.auth
    );
    const specificUserRoutes = routeList?.find((route) =>
        route.role.some((role) => role === userData?.role)
    );
    const headerRoutes = specificUserRoutes?.routes?.filter(
        (route) => route.showInHeader
    );
    const {pathname} = useLocation();
    const wishlist = useSelector((state: RootState) => state.wishlist);
    const cart = useSelector((state: RootState) => state.cart);

    return (
        <div className="bg-white py-1 py-md-3 position-fixed top-0 w-100 border-bottom z-3">
            <div className="container px-0">
                <div className="row align-items-center d-none d-md-flex">
                    <div className="col-md-7 d-flex align-items-center px-0">
                        <ImageHandler
                            src={pageLogo}
                            onClick={() => {
                                navigation(getInitialRoute(userData?.role as ERole));
                                scrollTo();
                                setIsMenuOpened(false);
                            }}
                            className="header-logo me-5 cursor-pointer"
                        />

                        {headerRoutes?.map((route) => {
                            const isActive = route?.path === pathname;
                            return (
                                <div
                                    key={route?.path}
                                    onClick={() => {
                                        navigation(route?.path);
                                        scrollTo();
                                    }}
                                    className={`cursor-pointer ${
                                        isActive ? 'text-primary' : ''
                                    } me-4 
                                    ${
                                        isAuthenticated &&
                                        route?.path === '/login'
                                            ? 'd-none'
                                            : ''
                                    }`}
                                >
                                    {!!route?.title?.length && (
                                        <div className="text-uppercase hover-underline-animation">
                                            {t(route?.title)}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>

                    <div className="col-md-5 d-flex justify-content-end align-items-center gap-4 px-0">
                        {userData?.role !== ERole.ADMIN && (
                            <>
                                <div
                                    className="position-relative cursor-pointer"
                                    onClick={() => {
                                        scrollTo();
                                        navigation('/users/wishlist');
                                    }}
                                >
                                    <GoHeart className="header-icons "/>
                                    {!!wishlist?.length && (
                                        <Badge
                                            className="bg-primary top-right-icon"
                                            style={{right: -15}}
                                        >
                                            {wishlist?.length}
                                        </Badge>
                                    )}
                                </div>
                                <div
                                    className="position-relative cursor-pointer"
                                    onClick={() => {
                                        scrollTo();
                                        navigation('/users/cart');
                                    }}
                                >
                                    <PiBagLight className="header-icons"/>
                                    {!!cart?.length && (
                                        <Badge
                                            className="bg-primary top-right-icon"
                                            style={{right: -15}}
                                        >
                                            {cart?.length}
                                        </Badge>
                                    )}
                                </div>
                            </>
                        )}

                        {isAuthenticated ? (
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="transparent"
                                    className="border-0 p-0"
                                    id="dropdown-basic"
                                >
                                    <CiUser className="header-icons"/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            navigation(
                                                `/myaccount?route=${
                                                    userData?.role === ERole.ADMIN ? 1 : 2
                                                }`
                                            );
                                        }}
                                    >
                                        {t('general.myAccount')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            localStorage.clear();
                                            navigation('/login');
                                            dispatch(authSlice.actions.setLogoutState());
                                            dispatch(wishlistSlice.actions.setWishlist([]));
                                            dispatch(cartSlice.actions.setCart([]));
                                        }}
                                    >
                                        {' '}
                                        {t('buttons.logout')}{' '}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="row d-md-none position-relative">
                    <div className="col-2">
                        <ImageHandler
                            src={pageLogo}
                            onClick={() => {
                                navigation(getInitialRoute(userData?.role as ERole));
                                scrollTo();
                                setIsMenuOpened(false);
                            }}
                            className="header-logo cursor-pointer"
                        />
                    </div>
                    <div className="col-8 d-flex align-items-center justify-content-end">
                        <div
                            className="position-relative cursor-pointer"
                            onClick={() => {
                                scrollTo();
                                navigation('/users/wishlist');
                            }}
                        >
                            <GoHeart className="header-icons "/>
                            {!!wishlist?.length && (
                                <Badge
                                    className="bg-primary top-right-icon"
                                    style={{right: -15}}
                                >
                                    {wishlist?.length}
                                </Badge>
                            )}
                        </div>
                        <div
                            className="position-relative cursor-pointer ms-2"
                            onClick={() => {
                                scrollTo();
                                navigation('/users/cart');
                            }}
                        >
                            <PiBagLight className="header-icons"/>
                            {!!cart?.length && (
                                <Badge
                                    className="bg-primary top-right-icon"
                                    style={{right: -15}}
                                >
                                    {cart?.length}
                                </Badge>
                            )}
                        </div>
                    </div>
                    <div className="col-2">

                        <div
                            className={`hamburger ${isMenuOpened ? 'toggle' : ''}`}
                            onClick={() => setIsMenuOpened((prev) => !prev)}
                        >
                            <div className="line1"></div>
                            <div className="line2"></div>
                            <div className="line3"></div>
                        </div>
                    </div>
                    {isMenuOpened && (
                        <div
                            className="col-12 position-fixed top-0 h-100 w-100 bg-dark d-flex flex-column align-items-center justify-content-center">
                            {headerRoutes?.map((route) => {
                                const isActive = route?.path === pathname;
                                return (
                                    <div
                                        key={route?.path}
                                        onClick={() => {
                                            navigation(route?.path);
                                            scrollTo();
                                            setIsMenuOpened(false);
                                        }}
                                        className={`cursor-pointer mb-5 ${
                                            isActive ? 'text-primary fw-bold' : 'text-white'
                                        }
                                    ${
                                            isAuthenticated &&
                                            route?.path === '/login'
                                                ? 'd-none'
                                                : ''
                                        }`}
                                    >
                                        {!!route?.title?.length && (
                                            <div className="text-uppercase hover-underline-animation">
                                                {t(route?.title)}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            {isAuthenticated && <div
                                onClick={() => {
                                    navigation(
                                        `/myaccount?route=${
                                            userData?.role === ERole?.ADMIN ? 1 : 2
                                        }`
                                    );
                                    scrollTo();
                                    setIsMenuOpened(false);
                                }}
                                className={`cursor-pointer mb-5 ${
                                    pathname === '/myaccount'
                                        ? 'text-primary fw-bold'
                                        : 'text-white'
                                }`}
                            >
                                <div className="text-uppercase hover-underline-animation">
                                    Profil
                                </div>
                            </div>}

                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
